<template>
  <q-card style="min-width: 600px">
    <q-card-section class="row items-center text-h6">
      TRV händelse Id: {{ eventId }}
    </q-card-section>
    <q-separator />
    <q-card-section class="q-pa-none">
      <table v-if="mpkData" style="table-layout: fixed; width: 100%">
        <tbody>
          <template v-for="h in headers" :key="h.key">
            <tr v-if="mpkData && mpkData[h.key]">
              <td
                style="
                  background: #f2f2f2;
                  font-weight: bold;
                  border-right: 1px solid rgba(0, 0, 0, 0.1);
                  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
                "
                class="q-px-md q-py-xs"
              >
                {{ h.label }}
              </td>
              <td
                class="q-px-md"
                style="border-bottom: 1px solid rgba(0, 0, 0, 0.1)"
              >
                {{ mpkData[h.key] }}
              </td>
            </tr>
          </template>
        </tbody>
      </table>
      <div v-else class="flex items-center justify-center q-pa-xl text-grey-6">
        Ingen data hittades...
      </div>
    </q-card-section>

    <q-separator />
    <q-card-actions align="right">
      <q-btn flat label="Stäng" @click="modal.closeModal" type="button" />
    </q-card-actions>
  </q-card>
</template>

<script lang="ts">
import { UseModal } from '@/composable/useModal'
import { defineComponent, inject } from 'vue'
import { DeviationDelayMpkModalData } from '@/types/deviation-delay-mpk-modal-data'

export default defineComponent({
  name: 'DeviationDelayMPKModal',

  setup() {
    const modal =
      inject<UseModal<DeviationDelayMpkModalData>>('delay-mpk-modal')!
    const { eventId, mpkData } = modal.state.data!

    const headers = [
      {
        label: 'Rubrik',
        key: 'Header',
      },
      {
        label: 'Händelsebeskrivning (intern)',
        key: 'InternalText',
      },
      {
        label: 'Händelsebeskrivning (extern)',
        key: 'ExternalDescription',
      },
    ]

    return { modal, eventId, mpkData, headers }
  },
})
</script>
