
import { UseModal } from '@/composable/useModal'
import { defineComponent, inject } from 'vue'
import { DeviationDelayMpkModalData } from '@/types/deviation-delay-mpk-modal-data'

export default defineComponent({
  name: 'DeviationDelayMPKModal',

  setup() {
    const modal =
      inject<UseModal<DeviationDelayMpkModalData>>('delay-mpk-modal')!
    const { eventId, mpkData } = modal.state.data!

    const headers = [
      {
        label: 'Rubrik',
        key: 'Header',
      },
      {
        label: 'Händelsebeskrivning (intern)',
        key: 'InternalText',
      },
      {
        label: 'Händelsebeskrivning (extern)',
        key: 'ExternalDescription',
      },
    ]

    return { modal, eventId, mpkData, headers }
  },
})
